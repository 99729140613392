// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-conservation-tsx": () => import("./../../../src/pages/conservation.tsx" /* webpackChunkName: "component---src-pages-conservation-tsx" */),
  "component---src-pages-contact-tsx": () => import("./../../../src/pages/contact.tsx" /* webpackChunkName: "component---src-pages-contact-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-media-tsx": () => import("./../../../src/pages/media.tsx" /* webpackChunkName: "component---src-pages-media-tsx" */),
  "component---src-pages-our-vision-tsx": () => import("./../../../src/pages/our-vision.tsx" /* webpackChunkName: "component---src-pages-our-vision-tsx" */),
  "component---src-pages-people-tsx": () => import("./../../../src/pages/people.tsx" /* webpackChunkName: "component---src-pages-people-tsx" */),
  "component---src-pages-test-search-tsx": () => import("./../../../src/pages/test-search.tsx" /* webpackChunkName: "component---src-pages-test-search-tsx" */),
  "component---src-templates-person-tsx": () => import("./../../../src/templates/person.tsx" /* webpackChunkName: "component---src-templates-person-tsx" */)
}

